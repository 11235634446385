document.addEventListener("turbolinks:load", () => {
  var Switchery = require('switchery');
  var elem = document.querySelector('.js-switch');

  if(elem) {
    var init = new Switchery(elem, {
      color: '#3bbfd5',
      secondaryColor: '#9decff',
    });
  }
})

document.addEventListener('turbolinks:before-cache', () => {
  $('.switchery').remove();
})