document.addEventListener('turbolinks:load', function(){
  $(document).on('click', '.list--item', function(e) {
    $('.list--item').removeClass('active');
    $(e.currentTarget).addClass('active');

    if($(window).width() < 575) {
      $('#messages-part').removeClass('d-none');
      $(e.currentTarget).parents('.contacts--left').addClass('d-none')
    }
  })

  $(document).on('click', '.card-body a', function(e) {
    $('.card-body a').removeClass('active');
    $(e.currentTarget).addClass('active');
  })

  $(document).on('click', '.blue-chat-xs-title .fa-arrow-left', function(e) {
    $('#messages-part').addClass('d-none');
    $('.contacts--left').removeClass('d-none');
    $('.list--item').removeClass('active');
  })

  // Scroll to the bottom of the messages part
  if ($('#messages-part').length) {
    $('#messages-part').scrollTop($('#messages-part')[0].scrollHeight + 100);
  }
})
