$(document).on('turbolinks:load', function() {
  $('.js-openable-action').on('click', function() {
    $('.js-openable-target').each(function(){
      if($(this).hasClass('d-none')) {
        $(this).removeClass('d-none');
      } else {
        $(this).addClass('d-none');
      }
    })
  })
})