function initTeamMemberCloseModal(css_id) {
  var selector = '.team-member-modal';

  if(css_id != '') {
    selector = '#' + css_id;
  }

  $(selector).on('shown.bs.modal', function (e) {
    var modal = $(e.target);
    var blockId = modal.data('blockid');
    var block = $('#' + blockId);
    var image_input = $(e.target).find('input[type=file]');

    image_input.on('change', function() {
      var reader = new FileReader();
      reader.readAsDataURL(this.files[0]);
      reader.onload = function (e) {
        block.find('.js-tm-image').attr('src', e.target.result);
        modal.find('img').attr('src', e.target.result);
        // set new value to file_updated_at hidden field to force save of team member model if only picture was updated
        modal.find('.js-file-updated-at-input').val(new Date().toUTCString());
      };
    });
  });

  $(selector).on('click', '.close', function(e){
    $(e.target).parents('.modal').modal('hide');
  })

  $(selector).on('click', '.js-team-member-modal-btn', function (e) {
    var modal = $(selector);
    var blockId = modal.data('blockid');
    var block = $('#' + blockId);

    var fullname = $(modal).find('.company_team_members_full_name > input').val();
    block.find('.tm-fullname').text(fullname);

    var position = $(modal).find('.company_team_members_position > input').val();
    block.find('.tm-position').text(position);

    var linkedinUrl = $(modal).find('.company_team_members_linkedin_url > input').val();
    block.find('.tm-linkedin-url').attr('href', linkedinUrl);
    if(linkedinUrl != '') {
      block.find('.tm-linkedin-url').removeClass('d-none');
    } else {
      block.find('.tm-linkedin-url').addClass('d-none');
    }

    var tellUsMore = $(modal).find('.company_team_members_tell_us_more > textarea').val();
    block.find('.tm-tell-us-more').text(tellUsMore);

    block.removeClass('d-none');

    if($('.team-member-block:visible').length >= 5) {
      $('.js-add-team-member').hide('slow');
    }
    $(selector).modal('hide');
  });
}

function initPreviousStepBtn() {
  $('.js-previous-step-btn').on('click', function(e) {
    e.preventDefault();

    $('#step').val(null);
    $('#new_opportunity').submit();
  });
}

function initOpportunityLabel() {
  $('.js-hidden-opportunity-label').on('click', function(e) {
    $('.js-hidden-opportunity').removeClass('d-none');
    $('.js-public-opportunity').addClass('d-none');
  });

  $('.js-public-opportunity-label').on('click', function(e) {
    $('.js-hidden-opportunity').addClass('d-none');
    $('.js-public-opportunity').removeClass('d-none');
  });
}

function toggleOpportunityDescription() {
  $('.js-show-opportunity-description').on('click', function(e) {
    e.preventDefault();
    $('.js-opportunity-description').toggleClass('d-none');
  });
}

window.initLoadMoreTagsButton = function() {
  $('.js-load-more-tags').on('click', function(e) {
    e.preventDefault();
    $(e.currentTarget).parent().find('span.d-none').removeClass('d-none').addClass('d-inline-block');
    $(e.currentTarget).hide();
  });
}

$(document).on('turbolinks:load', function () {
  initLoadMoreTagsButton();

  $('#to_contact-tab').on('click', function(e) {
    $('#to_contact-tab .new-flag').addClass('d-none');
    $('#to_contact-tab .new-flag').removeClass('d-inline-block');
  });

  $('#company_structure_ids').on('change', function(e) {
    var startup_id = $('.js-growth-stage select').data('growth-stage-visible-id');
    if (e.target.value == startup_id) {
      $('.js-growth-stage').removeClass('d-none');
    } else {
      $('.js-growth-stage').addClass('d-none');
    }
  });

  $("[data-form-prepend]").click(function(e) {
    var obj = $($(this).attr("data-form-prepend"));
    var id  = new Date().getTime();
    var modal_id = 'teamMemberModal-' + id;
    var block_id = 'teamMemberBlock-' + id;

    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });

    $.each(obj, function(_index, element) {
      if($(element).hasClass('modal')) {
        $(element).attr('id', modal_id);
        $(element).data('blockid', block_id);
      } else if($(element).hasClass('team-member-block')) {
        $(element).attr('id', block_id);
        $(element).find('.tm-update').attr('data-target', '#' + modal_id);
      }
    });

    obj.insertBefore(this);
    $('#' + modal_id).modal();

    initTeamMemberDeleteBtn();
    initTeamMemberCloseModal(modal_id);

    return false;
  });

  initTeamMemberCloseModal('');
  initPreviousStepBtn();
  initOpportunityLabel();
  toggleOpportunityDescription();
})
