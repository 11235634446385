/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
require("@rails/actiontext");
require("@rails/activestorage").start();
require("trix");
require("turbolinks").start();
require.context('../images', true)
// require("channels")

import 'bootstrap';
import select2 from 'select2/dist/js/select2.min.js'
import './select2_behaviors';
import './company';
import './candidate';
import '../stylesheets/application';
import './form_row_duplicator';
import './add_experience';
import './add_hidden_company';
import './switchery_config';
import './add_talent_pool_company';
import './fake_link';
import './limit_number_of_words';
import './contacts';
import './autogrow_textarea';
import './infinite-scroll';
import './dashboard';
import './flash';
import './image_preview';
import './openable-menu';
import './visibility';
import './modal_options';


document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  // JQuery setup:
  global.$ = $
  global.jQuery = $
})
