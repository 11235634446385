function initDefaultSelect2(element = null, inModal = false) {
  let config = {width: '100%'};
  let $target = element ? $(element) : null;

  if (!!$target) {
    $target.select2(config);
  } else {
    $('.default-select2').select2(config);
  }
}

function initNoSearchSelect2() {
  $('.no-search-select2').select2({
    minimumResultsForSearch: Infinity,
    width: '100%'
  });

  $('.no-multiple-search-select2').on('select2:opening select2:closing', function( event ) {
    var $searchfield = $(this).parent().find('.select2-search__field');
    $searchfield.prop('disabled', true);
  });
}

function initNoSearchWithImage() {
  $('.no-search-with-image-select2').select2({
    minimumResultsForSearch: Infinity,
    width: '100%',
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: FormatRepoWithImage,
    templateSelection: FormatRepoWithImage
  });
}

// Use to display the name with is icon
function FormatRepoWithImage(repo) {
  if (repo.loading) return repo.text;
  var markup = "<div class='select2-result-repository clearfix'>"

  if(repo.element.dataset.icon) {
    markup += "<div class='select2-result-repository__avatar'><img src='"
    markup += repo.element.dataset.icon
    markup +=  "' /></div>"
  }

    markup += "<div class='select2-result-repository__meta'>" +
      "<div class='select2-result-repository__title'>"
    markup +=   repo.title || repo.text
    markup += "</div></div></div>"

  return markup;
}

export function initTagSelect2(element = null, inModal = false) {
  let config = {
    createTag: function (params) {
      var term = $.trim(params.term);

      if (term === '') {
        return null;
      }

      return {
        id: term,
        text: term,
        newTag: true // add additional parameters
      }
    },
    width: '100%'
  };

  let $target = element ? $(element) : null;

  if (!!$target) {
    $target.select2(config);
  } else {
    $('.tag-select2').select2({
      tags: true,
      width: '100%',
      maximumSelectionLength: 2
    });
  }
}

let ajaxDefaultConfig = {
  width: '100%',
  insertTag: function (data, tag) {
    data.push(tag);
  },
  ajax: {
    dataType: 'json',
    delay: 250,
    processResults: function (data) {
      return {
        results: data
      };
    },
  },
  escapeMarkup: function (markup) {
    return markup;
  },
  minimumInputLength: 2,
  templateResult: formatRepo,
  templateSelection: formatRepo
}

export function initTagAjaxSelect2(element = null) {
  let config = ajaxDefaultConfig
  config.tags = true
  let $target = element ? $(element) : null;

  if (!!$target) {
    $target.select2(config);
  } else {
    $('.tagable-ajax-select2').select2(config);
  }
}

// Use ajax to display select2
export function initAjaxSelect2(element = null) {
  let config = ajaxDefaultConfig
  config.tags = false

  let $target = element ? $(element) : null;

  if (!!$target) {
    $target.select2(config);
  } else {
    $('.ajax-select2').select2(config);
  }
}

export function destroyAllSelect2() {
  if ($('.default-select2').length > 0) { $('.default-select2').select2('destroy') };
  if ($('.tag-select2').length > 0) { $('.tag-select2').select2('destroy') };
  if ($('.tagable-ajax-select2').length > 0) { $('.tagable-ajax-select2').select2('destroy') };
  if ($('.ajax-select2').length > 0) { $('.ajax-select2').select2('destroy') };
  if ($('.no-search-select2').length > 0) { $('.no-search-select2').select2('destroy') };
  if ($('.no-search-with-image-select2').length > 0) { $('.no-search-with-image-select2').select2('destroy') };
}

export function initAllSelect2() {
  initDefaultSelect2();
  initTagSelect2();
  initAjaxSelect2();
  initTagAjaxSelect2();
  initNoSearchSelect2();
  initNoSearchWithImage();
}

// Use to display the name with is icon
function formatRepo(repo) {
  if (repo.loading) return repo.text;
  var markup = "<div class='select2-result-repository clearfix'>"

    markup += "<div class='select2-result-repository__avatar'><img src='"
    if (repo.logo) {
      markup += repo.logo
    } else {
      markup += require('../images/logo-company-default.svg')
    }

    markup +=  "' /></div>"

    markup += "<div class='select2-result-repository__meta'>" +
      "<div class='select2-result-repository__title'>"
    markup +=   repo.title || repo.text
    markup += "</div></div></div>"

  return markup;
}


$(document).on('turbolinks:load', function () {
  initAllSelect2();
})

$(document).on("turbolinks:before-cache", function() {
  destroyAllSelect2();
});
