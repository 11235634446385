import { destroyAllSelect2 } from './select2_behaviors';
import { initAllSelect2 } from './select2_behaviors';
import { syncCloneHtmlAttributes } from './form_row_duplicator';


function candidateAction() {
  $('.js-candidate--experience_add').click(function(event) {
    let $parentElement = $(event.currentTarget).parents('.add--experience');
    let $selectedCompany = $parentElement.find('#add_values_company').select2('data')[0];
    let $selectedJob = $parentElement.find('#add_values_job').select2('data')[0]

    if(($selectedCompany.title  == '' && $selectedCompany.text == '') || $selectedJob.text == '') { return false }

    let logo = $selectedCompany.logo || require('../images/logo-company-default.svg');

    let randomNumber = new Date().getUTCMilliseconds();

    let $clonedPosition = $('.candidate--position').last().clone(true);

    $clonedPosition.removeClass('d-none')
    $clonedPosition.find('.logo img').attr('src', logo);
    $clonedPosition.find('.title').text($selectedCompany.title || $selectedCompany.text);
    $clonedPosition.find('.candidate_experiences_company_id input').val($selectedCompany.id);
    $clonedPosition.find('.job-title').text($selectedJob.title || $selectedJob.text);
    $clonedPosition.find('.candidate_experiences_job_id input').val($selectedJob.id);

    syncCloneHtmlAttributes($clonedPosition, randomNumber);
    $clonedPosition.find('.remove-duplication input').val(0)

    $('.duplicated-elements').append($clonedPosition);
    updatePositions();
  })
}

function updatePositions() {
  $('.candidate--position').not('.d-none').each(function(index){
    $(this).find('.position-input').val(index + 1);
  })
}

function removeAssociation() {
  $('.remove-duplication .js-duplicate-remove').click(function(event) {
    let $parentElement = $(event.currentTarget).parents('.candidate--position');
    $parentElement.addClass('d-none');
    $(event.currentTarget).prev('input').val(true)
    updatePositions();
  })
}

function moveElement() {
  $('.actions .up').click(function(event) {
    moveUp(event);
  });

  $('.actions .down').click(function(event) {
    moveDown(event);
  });
}

function moveDown(event) {
  let $target = $(event.currentTarget);
  let $element = $target.parents('.candidate--position');
  let $after = $element.nextAll('div.candidate--position:first')
  $element.insertAfter($after);
  updatePositions();
}

function moveUp(event) {
  let $target = $(event.currentTarget);
  let $element = $target.parents('.candidate--position');
  let $before = $element.prevAll('div.candidate--position:first')
  $element.insertBefore($before);
  updatePositions();
}

$(document).on('turbolinks:load', function() {
  candidateAction();
  removeAssociation();
  moveElement();
})
