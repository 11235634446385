$(document).on('turbolinks:load', function() {

  var image_input = $('.js-preview-picture-block').find('input[type=file]');

  image_input.on('change', function() {
    var reader = new FileReader();
    reader.readAsDataURL(this.files[0]);
    reader.onload = function (e) {
      $('.js-preview-picture-block').find('img').attr('src', e.target.result);
    };
  });

})