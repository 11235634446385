$(document).on('turbolinks:load', function() {

  var timeout = null;
  var lastScroll = 0

  $('.js-infinite-scroll').scroll(function() {
    clearTimeout(timeout);
    var $target = $(event.currentTarget)
    var scroll = $('.js-infinite-scroll').scrollTop();
    if(scroll >= lastScroll) {
      timeout = setTimeout(function () {
        $target.find('.js-infinite-scroll-target-link')[0].click();
        var currentRef = $('.js-infinite-scroll-target-link').attr('href');
        var lastPage = parseInt(currentRef.slice(-1));
        $('.js-infinite-scroll-target-link').attr('href', currentRef.replace(/.$/, lastPage + 1))
      }, 1000)
      lastScroll = scroll;
    }

  })

})

