import { syncCloneHtmlAttributes } from './form_row_duplicator';

$(document).on('turbolinks:load', function() {
  $('.js-add-company-visibility').on('click', function(event) {
    let $parentElement = $(event.currentTarget).parents('.add-hide-company');
    let $selectedData = $parentElement.find('#companies_search_company').select2('data')[0];

    if($selectedData.title  == '' && $selectedData.text == '') { return false }

    let logo = $selectedData.logo || require('../images/logo-company-default.svg');

    let $clonedCompany = $('.hidden-company').last().clone(true);

    $clonedCompany.find('.logo img').attr('src', logo);
    $clonedCompany.find('.title').text($selectedData.title || $selectedData.text);
    $clonedCompany.find('input').val($selectedData.id);
    $clonedCompany.removeClass('d-none');

    syncCloneHtmlAttributes($clonedCompany, $selectedData.id);

    $('.hidden-companies').append($clonedCompany);

    $parentElement.find('#companies_search_company').val(null).trigger('change')
  })

  $(document).on('click', '.hidden-company .js-duplicate-delete', function(event) {
    $(event.currentTarget).parents('.hidden-company').remove();
  })
})
