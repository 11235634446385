$(document).on('turbolinks:load', function () {
  $('.js-add-languages').on('click', function(e) {
    e.stopPropagation()
    if($('.candidate_language_skills_language').length >= 8) {
      $('.js-add-languages').hide('slow');
    }
    return false;
  });

  $('.js-destroy-link').on('click', function(e) {
    var candidatePicture = $('.candidate-picture.d-inline-block');
    var defaultPicture = $('.candidate-picture.d-none');

    candidatePicture.addClass('d-none');
    candidatePicture.removeClass('d-inline-block');

    defaultPicture.removeClass('d-none');
    defaultPicture.addClass('d-inline-block');
  });

  $('#js-open-candidate-toggle').on('change', function() {
    if($(this).is(':checked')) {
      $('.js-candidate-open').toggleClass('d-block');
      $('.js-candidate-open').toggleClass('d-none')

      $('.js-candidate-not-open').toggleClass('d-none');
      $('.js-candidate-not-open').toggleClass('d-block')

      $('.js-candidate-status-switch').addClass('open');
    } else {
      $('.js-candidate-not-open').toggleClass('d-block');
      $('.js-candidate-not-open').toggleClass('d-none')

      $('.js-candidate-open').toggleClass('d-none');
      $('.js-candidate-open').toggleClass('d-block');

      $('.js-candidate-status-switch').removeClass('open');
    }

    form = document.querySelector('.edit_candidate_open');
    form.dispatchEvent(new Event('submit', { bubbles: true }));
    // https://github.com/rails/rails/issues/29546#issuecomment-313572043
  });

  $('.js-company-for-pool').on('change', function () {
    if (this.value != '') {
      var button = $(this).parents('form').find('.btn-warning');
      button.addClass('d-inline-block');
      button.removeClass('d-none');

      if($('#joinCompanyModal-0')) {
        var input = $('#joinCompanyModal-0').find('#talent_pool_company_id').val(this.value);
      }
    }
  });

  if($('.js-pools-nav').length) {
    $('.js-pools-nav .nav-link').on('click', function(e) {
      // Remove active class of all the elements
      $('.js-pools-nav .nav-link').each(function() {
        $(this).parent().removeClass('active');
      });
      // and add this class to the current element
      $(this).parent().addClass('active');
    })

    // Add active class after page loading
    $('.js-pools-nav .nav-link.active').each(function() {
      $(this).parent().addClass('active');
    });
  }

  $('.language-delete').on('click', function(e) {
    e.preventDefault();

    var input = $(e.target).parents('.language-block').find('.destroy-element');
    $(input).val(true);
    var block = $(e.target).parents('.language-block');
    $(block).hide('slow');
  })
});
