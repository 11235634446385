function limitNumberOfWords() {
  $('.limit-number-of-words').keydown(function(e) {
    $target = $(e.currentTarget)
    var maxWords = $target.data('maxWords'), currentWords; // Maximum word length
    currentWords = $target.val().split(/[\s]+/);
    const $WordCounterTarget = $target.parents('.word-counter-block').find('.word-counter')
    if (currentWords.length > maxWords) {
      $WordCounterTarget.removeClass('text-success');
      $WordCounterTarget.addClass('text-danger');
      if ( e.keyCode == 46 || e.keyCode == 8 ) {// Allow backspace and delete buttons
      } else if (e.keyCode < 48 || e.keyCode > 57 ) {//all other buttons
        e.preventDefault();
      }
    } else {
      $WordCounterTarget.removeClass('text-danger');
      $WordCounterTarget.addClass('text-success');
    }
    $WordCounterTarget.text(currentWords.length + ' / ' + maxWords);
  });
}


$(document).on('turbolinks:load', function () {
  limitNumberOfWords();
})