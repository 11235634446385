import { destroyAllSelect2 } from './select2_behaviors';
import { initAllSelect2 } from './select2_behaviors';

function initFormRowDuplicator(inModal = false) {
  $('.js-duplicate-row, .v2-duplicator .duplicate-action').on('click', '.btn', (event) => {
    // Set variables:
    var $currentButton = $(event.currentTarget);
    if($currentButton.hasClass('js-v2-duplicate-add')) {
      var $currentRow = $currentButton.parents('.language-skills').find('.js-duplicate-row').last();
      var $rowsContainer = $currentRow.parent();
    } else {
      var $currentRow = $currentButton.closest('.js-duplicate-row');
      var $rowsContainer = $currentRow.parent();
    }

    // Manage Cloning:
    if ($currentButton.hasClass('js-duplicate-add') || $currentButton.hasClass('js-v2-duplicate-add')) {
      // Duplicate Element
      manageCloningBehavior($currentRow, $rowsContainer, $currentButton);
    } else if ($currentButton.hasClass('js-duplicate-remove')) {
      // Toggle Destroy button
      manageDestroyBehavior($currentButton);
    } else {
      // Removing duplicate
      manageCloneDeletionBehavior($currentRow, $rowsContainer.find('.js-duplicate-row'));
    }

  });
}

function manageCloneDeletionBehavior($row, $rows) {
  if ($row.hasClass('js-duplicate')) {
    let rowIndex = $rows.index($row);
    let previousRow = $rows[rowIndex - 1];

    if (previousRow) {
      $row.trigger('destroy');
      $row.remove();
      $($rows[0]).find('.js-duplicate-add').attr('hidden', false);
    } else {
      alert("Previous sibling element not found!!");
    }
  } else {
    alert("Can't delete row that are not duplicates!");
  }
}

// Function used to manage cloning behavior of rows
function manageCloningBehavior($row, $rowContainer, $currentButton) {
  let rowsCount = $rowContainer.find('.js-duplicate-row').length;
  let duplicationLimit = $row.find('.js-duplicate-constraint').data('size');
  if (rowsCount < duplicationLimit) {
    // authorize Cloning
    destroyAllSelect2(); // Remove Select2 due to the Cloning
    let $rowClone = $row.clone(true);
    $rowClone.addClass('js-duplicate');

    // Update clone attributes
    syncCloneHtmlAttributes($rowClone, new Date().getUTCMilliseconds());

    // Render hidden attribute visible if it's not
    // debugger
    $rowClone.find('.js-duplicate-delete').attr('hidden', false);
    $row.find('.js-duplicate-add').attr('hidden', true);
    $rowClone.find('.js-duplicate-remove').attr('hidden', true);

    // Hide duplicate button when limit is reached!
    if (rowsCount == (duplicationLimit - 1)) {
      $row.find('.js-duplicate-add').attr('hidden', true);
    }

    // Append clone to container
    $rowClone.appendTo($rowContainer);
    $rowClone.removeAttr('data-language_skill_id');

    // Reinit select2 element after appending clone
    initAllSelect2();
  } else {
    // Prevent Cloning
    alert("Can't duplicate elements because the max number of elements has been reached!");
  }
}

// Function used to add the class `active` on the label
// Because of the CSS tricks used the cliked element could be:
// - the invisble container => a `span`
// - the visible container => a `label`
// - the nested icon => a `i`
function manageDestroyBehavior($element) {
  if ($element.is('i')) {
    $element.parent().toggleClass('active');
  } else if ($element.is('span')) {
    $element.find('label').toggleClass('active');
  } else {
    $element.toggleClass('active');
  }
  $element.parents('.js-duplicate-row').trigger('destroy');
}

// Function used to synchronized clone html attributes
// Parameters:
// // $rowClone, jQuery object of cloned html element
// // rowsCount, number of rows in the parent container
// No returns, clone elements references are updated
export function syncCloneHtmlAttributes($rowClone, rowsCount) {
  const underscoreRegex = /(.*_)(\d+)(_.*)/;
  const squareBracketRegex = /(\w+\[\w+\]\[)(\d+)(\]\[\w+\])/;
  const newNumber = rowsCount;

  $rowClone.find('.form-group, .form-check').each((_, formGroupDiv) => {
    let $formGroupDiv = $(formGroupDiv);

    // Change Label
    let $label = $formGroupDiv.find('label');
    if($label.length > 0) {
      updateHtmlAttributeReference($label, 'for', underscoreRegex, newNumber);
    }

    // Change Select 'id' and 'name'
    $formGroupDiv.find('.form-control, .form-check-input').each((_, formControl) => {
      let $formControl = $(formControl);

      updateHtmlAttributeReference($formControl, 'id', underscoreRegex, newNumber);
      updateHtmlAttributeReference($formControl, 'name', squareBracketRegex, newNumber);
    });
  });
}

// Function used to replace the html attribute number reference by a provided value
// Parameters:
// // $htmlElement, jQuery object referencing a html element
// // htmlAttribute, concerned attribute
// // regex, regex allowing to make the modification
// // number, number replacing the previous value
// No returns, element is updated
function updateHtmlAttributeReference($htmlElement, htmlAttribute, regex, number) {
  let attributeObject = $htmlElement.attr(htmlAttribute);
  let splittedReferences = attributeObject.replace(regex, referencesSplitter);
  let references = splittedReferences.split(',');

  references.splice(1, 0, number);

  $htmlElement.attr(htmlAttribute, references.join(''));

  function referencesSplitter(_, firstPart, _number, lastPart) {
    return [firstPart, lastPart];
  }
}

$(document).on('turbolinks:load', function () {
  initFormRowDuplicator();
});
