import { syncCloneHtmlAttributes } from './form_row_duplicator';

$(document).on('turbolinks:load', function() {
  $('.js-add-talent-pool-company').on('click', function(event) {
    let $parentElement = $(event.currentTarget).parents('.add-talent-pool-company');
    let $selectedData = $parentElement.find('#companies_search_company').select2('data')[0];

    let $existedValues = $('.check__boxes--button input').map(function() {
        return $(this).val();
    });

    if($existedValues.toArray().includes($selectedData.id)) return false;

    let logo = $selectedData.logo || require('../images/logo-company-default.svg');

    let $clonedCompany = $('.talent--pool__item').last().clone(true);

    $clonedCompany.find('.logo img').attr('src', logo);
    $clonedCompany.find('.title').text($selectedData.title);
    $clonedCompany.find('input').val($selectedData.id);
    $clonedCompany.find('input').attr('id', `candidate_talent_pool_company_ids_#{$selectedData.id}`);
    $clonedCompany.find('label').attr('for', `candidate_talent_pool_company_ids_#{$selectedData.id}`);
    $clonedCompany.find('.city').remove();

    syncCloneHtmlAttributes($clonedCompany, $selectedData.id);

    $('.talent--pool').append($clonedCompany);
  })
})
